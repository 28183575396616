<template>

    <div>
        <form>
            <div v-for="child in children">
                <div class="form-group row">
                    <label :for="'sel'+child"
                        class="col-lg-2 col-md-3 col-sm-3 col-xs-12 col-form-label pr-0">{{child}}. {{voMsg('quote.child')}}</label>
                    <div class="col-lg-10 col-md-9 col-sm-9 align-self-center">
                        <select :id="'sel'+child"
                            :name="'sel'+child"
                            v-model="childrenModel[child]"
                            @change="onChange($event)"
                            class="form-control">
                            <option value="undefined"
                                selected>{{voMsg('quote.unknownage')}}</option>

                            <option v-for="a in ages"
                                :value="a">
                                <span v-if="a == 0">{{a}} {{voMsg('quote.years')}}</span>
                                <span v-if="a == 1">{{a}} {{voMsg('quote.year')}}</span>
                                <span v-if="a > 1">{{a}} {{voMsg('quote.years')}}</span>
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </div>



</template>
<script>
    import V from 'voUtils/V.js';

    export default {
    	name: 'childrenage',
    	voVueComponent: 'childrenage',
    	data() {
    		return {
    			childrenModel: []
    		}
    	},
    	props: {
    		children: Number,
    		callback: Function
    	},
    	computed: {
    		ages: function() {
    			var ages = [];
    			for (var i = 3; i <= 15; i++) {
    				ages.push(i);
    			}
    			return ages;
    		}
    	},
    	methods: {
    		onChange(event) {

    			let childrenArray = this.childrenModel;
    			let count = 0
    			let childrenAgesArray = [];
    			let childrenAgesString = '';

    			for (let i = 0; i < childrenArray.length; i++) {
    				if (parseInt(childrenArray[i]) >= 0) {
    					//console.log('ArrayValue::', childrenArray[i]);
    					childrenAgesArray.push(childrenArray[i]);
    					count++;
    				}
    			}
    			console.log('count::' + count);
    			console.log('this.children::' + this.children);
    			if (count === this.children) {
    				childrenAgesString = childrenAgesArray.toString();
    				console.log('childrenAgesString::' + childrenAgesString);
    				this.callback(childrenAgesString);

    				//console.log('Kinderalter komplett', childrenAgesString);
    				document.getElementById('children-age-notice').style.display = 'none';
    				document.getElementById('booknowbutton').disabled = false;
    			} else {
    				document.getElementById('children-age-notice').style.display = 'block';
    				document.getElementById('booknowbutton').disabled = true;
    			}

    		},
    	},
    	watch: {
    		children: function(newVal, oldVal) {
    			//console.log('Prop changed: ', newVal, ' | was: ', oldVal)
    			this.childrenModel = [];

    		}
    	}
    }
</script>