var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      _vm._l(_vm.children, function(child) {
        return _c("div", [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              {
                staticClass:
                  "col-lg-2 col-md-3 col-sm-3 col-xs-12 col-form-label pr-0",
                attrs: { for: "sel" + child }
              },
              [_vm._v(_vm._s(child) + ". " + _vm._s(_vm.voMsg("quote.child")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-10 col-md-9 col-sm-9 align-self-center" },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.childrenModel[child],
                        expression: "childrenModel[child]"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "sel" + child, name: "sel" + child },
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.childrenModel,
                            child,
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        function($event) {
                          return _vm.onChange($event)
                        }
                      ]
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { value: "undefined", selected: "" } },
                      [_vm._v(_vm._s(_vm.voMsg("quote.unknownage")))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.ages, function(a) {
                      return _c("option", { domProps: { value: a } }, [
                        a == 0
                          ? _c("span", [
                              _vm._v(
                                _vm._s(a) +
                                  " " +
                                  _vm._s(_vm.voMsg("quote.years"))
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        a == 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(a) +
                                  " " +
                                  _vm._s(_vm.voMsg("quote.year"))
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        a > 1
                          ? _c("span", [
                              _vm._v(
                                _vm._s(a) +
                                  " " +
                                  _vm._s(_vm.voMsg("quote.years"))
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  ],
                  2
                )
              ]
            )
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }